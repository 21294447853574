
import { defineComponent, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import EmployeeModal from "@/components/modals/forms/employee/EmployeeModal.vue";

export default defineComponent({
  name: "profile",
  components: {
    Datatable,
    EmployeeModal,
  },
  setup() {
    const route = useRoute();
    const entityInfoID = route.params.id;
    return { entityInfoID };
  },
  data() {
    return {
      tableHeader: [
        {
          name: "Action",
          key: "actions",
          sortable: true,
        },
        {
          name: "Name",
          key: "name",
          sortable: true,
        },
        {
          name: "Designation",
          key: "designation",
          sortable: true,
        },
        {
          name: "Date of Birth",
          key: "dob",
          sortable: true,
        },
        {
          name: "NID",
          key: "nid",
          sortable: false,
        },
        {
          name: "Mobile",
          key: "mobile",
          sortable: false,
        },
        {
          name: "Email",
          key: "email",
          sortable: false,
        },
      ],
      lists: [],
      search: "",
      tableData: [],
      data: {},
      tabIndex: ref(0),
      entityInfos: [],
    };
  },
  async created() {
    await this.getEmployee();
    await this.getEntity();
    Object.assign(this.tableData, this.lists);
  },
  methods: {
    setActiveTab(event) {
      this.tabIndex = parseInt(event.target.getAttribute("data-tab-index"));
    },
    async getEmployee() {
      await ApiService.get("employee/search?entity_info=" + this.entityInfoID)
        .then((response) => {
          this.lists = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntity() {
      await ApiService.get("entity/infos/" + this.entityInfoID)
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    searchItems() {
      if (this.search !== "") {
        let results: never[] = [];
        for (let j = 0; j < this.tableData.length; j++) {
          if (this.searchingFunc(this.tableData[j], this.search)) {
            results.push(this.tableData[j]);
          }
        }
        this.tableData.splice(0, this.tableData.length, ...results);
      } else {
        Object.assign(this.tableData, this.lists);
      }
    },

    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    },

    edit(data) {
      this.emitter.emit("edit-modal-data", data);
    },

    add() {
      this.$router.push({ name: "entity employee", params: { id: 1 } });
    },

    view(id) {
      this.$router.push({
        name: "entityEmployeeProfiles",
        params: { entity: this.entityInfoID, id: id },
      });
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete("employee/newProfile/" + `${id}`)
            .then((response) => {
              this.emitter.emit("infos-updated", true);
              Swal.fire("Deleted!", response.data.message, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
});
